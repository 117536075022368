<template>
  <div>
    <div style="margin: 20px 0 0px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">

      <Table border :columns="columns" :loading="loading" :data="data"></Table>
      <div class="page">
        <Page :total="total" @on-change="changePage" :current="page" show-total show-sizer show-elevator></Page>
      </div>
      <DeleModal :status="deleModalStatus" :txt="$t('dataset_data_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
    </div>
  </div>

</template>

<script>
import DeleModal from '../../components/deleteModal.vue';
import util from '@/utils/tools.js';
export default {
  name: "role",
  data(){
    return{
      columns: [
        {
          title: 'ID',
          key:'id',
        },
        {
          title: '知识图谱',
          render: (h, para) => {
            return h('span', para.row.map_id );
          }
        },
        {
          title: '题库',
          render: (h, para) => {
            return h('span', para.row.category_id );
          }
        },
        {
          title: '知识点对名称',
          render: (h, para) => {
            return h('span', para.row.node_name);
          }
        },
        {
          title: '标签对名称',
          render: (h, para) => {
            return h('span', para.row.label_name);
          }
        },
        {
          title: '试题',
          render: (h, para) => {
            return h('span', para.row.data_id );
          }
        },
        {
          title: '文本匹配度',
          render: (h, para) => {
            return h('span', para.row.score + '/'+para.row.score_avg);
          }
        },
        {
          title: '文本匹配结果',
          render: (h, para) => {
            return h('span', para.row.result );
          }
        },
        {
          title: '语义匹配度',
          render: (h, para) => {
            return h('span', para.row.ai_score );
          }
        },
        {
          title: '匹配提示语',
          render: (h, para) => {
            return h('span', para.row.prompt );
          }
        },
        {
          title: '匹配结果',
          render: (h, para) => {
            return h('span', para.row.ai_result );
          }
        },
        {
          title: '是否匹配',
          render: (h, para) => {
            return h('span', para.row.is_effect );
          }
        },
        {
          title: this.$t('dataset_dataCorrection_table_created_at'),
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000), 'MM-dd hh:mm'
                )
            );
          }
        },
        // {
        //   title: this.$t('dataset_dataCorrection_table_op'),
        //   minWidth:50,
        //   render: (h, params) => {
        //     return h('div', [
        //       h('Button', {
        //         props: {
        //           type: 'info',
        //           size: 'small'
        //         },
        //         on: {
        //           click: () => {
        //             this.verify(params.row);
        //           }
        //         }
        //       }, params.row.is_effect > 0 ? '改为未匹配': '改为精准匹配'),
        //     ]);
        //   }
        // }
      ],
      data: [],
      loading:false,
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      page:1,
      pageSize:10,
      total:0,
      modalStatus:false,
      ai_train_id:0,
      formItem:{
        id:'',
        name:'',
        data_count:'',
        desc:'',
      },
      ruleValidate: {
        id: [
          { required: true, message: this.$t('dataset_dataCorrection_add_id_tip'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('dataset_dataCorrection_add_name_tip'), trigger: 'blur' }
        ],
        data_count: [
          { message: this.$t('dataset_dataCorrection_add_data_count_tip'), trigger: 'blur' }
        ],
        desc: [
          { message: this.$t('dataset_dataCorrection_add_desc_tip'), trigger: 'blur' },
        ],
      },
      addBtnLoading:false,
      type:'',
      modalTit:'',
      typeObj:{},
      levelObj:{},
    }
  },
  components:{
    DeleModal
  },
  mounted(){
    if(this.$route.query.map_id){
      this.map_id = this.$route.query.map_id;
    }
    this.getList();
  },
  methods:{
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        site_id:-1,
        user_id:-1,
        map_id:this.map_id,
      };
      this.api.dataset.mapAiMatchKeypointList(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
        this.typeObj = res.types;
        this.levelObj = res.levels;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    delete(data){
      this.curDeleData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        id:this.curDeleData.id
      }
      this.modalLoading = true;
      this.api.dataset.dataUserCollectDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    changePage(page){
      console.log(page,'changePage')
      this.page = page;
      this.getList();
    },
    cancel(){
      this.modalStatus = false;
      this.$refs.formCustom.resetFields();
    },
    verify(row){
      let data = {
        ai_train_id:row.ai_train_id,
        data_id:row.data_id,
      }
      this.modalLoading = true;
      this.api.dataset.mapAiTrainDataVerify(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;
  .top{
    text-align: left;
    margin-bottom: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
